import {DatabaseService} from './database.service';
import {Injectable} from '@angular/core';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly serviceModel: string;
  public user: User;

  constructor(private databaseService: DatabaseService) {
    this.serviceModel = 'user';
  }

  public async changePassword(user: any): Promise<any> {
    try {
      return await this.databaseService.patchRequest(user, `${this.serviceModel}/change_password`);
    } catch (error) {
      throw error;
    }
  }

  public async checkClient(body: any, id: number): Promise<any> {
    try {
      return await this.databaseService.patchRequest(body, `${this.serviceModel}/check_client/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async deleteUser(id: number): Promise<any> {
    try {
      return await this.databaseService.deleteRequest(`${this.serviceModel}/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async disableUser(id: number): Promise<any> {
    try {
      return await this.databaseService.patchRequest({}, `${this.serviceModel}/disable/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async enableUser(id: number): Promise<any> {
    try {
      return await this.databaseService.patchRequest({}, `${this.serviceModel}/enable/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async forgotPassword(user: any): Promise<any> {
    try {
      return await this.databaseService.postRequest(user, `${this.serviceModel}/forgot_password`);
    } catch (error) {
      throw error;
    }
  }

  public async forgotPasswordVerify(body: any, token: string): Promise<any> {
    try {
      return await this.databaseService.patchRequest(body, `${this.serviceModel}/forgot_password_verify/${token}`);
    } catch (error) {
      throw error;
    }
  }

  public async forwardToAdmin(): Promise<any> {
    try {
      return await this.databaseService.patchRequest({}, `${this.serviceModel}/forward`);
    } catch (error) {
      throw error;
    }
  }

  public async getMe(reloadUser?: boolean): Promise<any> {
    try {
      if (this.user && !reloadUser) {
        return this.user;
      }
      this.user = await this.databaseService.getRequest(`${this.serviceModel}/me`);
      return this.user;
    } catch (error) {
      throw error;
    }
  }

  public async getUsers(): Promise<any> {
    try {
      return await this.databaseService.getRequest(this.serviceModel);
    } catch (error) {
      throw error;
    }
  }

  public async insertUser(user: any): Promise<any> {
    try {
      return await this.databaseService.postRequest(user, this.serviceModel);
    } catch (error) {
      throw error;
    }
  }

  public async login(user: any): Promise<any> {
    try {
      return await this.databaseService.postRequest(user, `${this.serviceModel}/login`);
    } catch (error) {
      throw error;
    }
  }

  public async logout(): Promise<any> {
    try {
      await this.databaseService.postRequest({}, `${this.serviceModel}/logout`);
      this.user = null;
    } catch (error) {
      throw error;
    }
  }

  public async registration(user: any): Promise<any> {
    try {
      return await this.databaseService.postRequest(user, `${this.serviceModel}/registration`);
    } catch (error) {
      throw error;
    }
  }

  public async rejectUser(body: any, id: number): Promise<any> {
    try {
      return await this.databaseService.patchRequest(body, `${this.serviceModel}/reject/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async reviewUser(body: any, id: number): Promise<any> {
    try {
      return await this.databaseService.patchRequest(body, `${this.serviceModel}/review/${id}`);
    } catch (error) {
      throw error;
    }
  }

  public async validateTwoFactorAuthentication(user: any): Promise<any> {
    try {
      return await this.databaseService.patchRequest(user, `${this.serviceModel}/validate_two_factor_authentication`);
    } catch (error) {
      throw error;
    }
  }

  public async verifyEmail(user: any): Promise<any> {
    try {
      return await this.databaseService.patchRequest(user, `${this.serviceModel}/verify_email`);
    } catch (error) {
      throw error;
    }
  }
}
